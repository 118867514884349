<template>

<div class="search" :class="{'is-full': full}">

	<input class="search-input" type="text" placeholder="Search..." v-bind:value="value" v-on:input="$emit('input', $event.target.value)" />

</div>

</template>

<script>

export default {

	props: ['value', 'full']

}

</script>

<style scoped>

.search {
	width: 100%;
	flex-shrink: 0;
	display: flex;
	margin-bottom: 20px;
}

.search.is-full {
	margin-bottom: 0px;
}

.search-input {
	height: 46px;
	background-color: #f5f5f5;
	border-radius: 23px;
	padding: 0px 30px;
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #343434;
	flex-grow: 1;
}

.search-input::placeholder {
	color: #1a234c;
	text-transform: uppercase;
}

.search-button {
	width: 46px;
	margin-left: 10px;
	flex-shrink: 0;
	height: 46px;
	background-color: #1a234c;
	color: #fff;
	line-height: 46px;
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
}

</style>
